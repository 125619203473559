<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_geston_bono_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Bonos
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_ini"
                        :class="
                          $v.filtros.fecha_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group  col-md-6">
                      <label>Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_fin"
                        :class="
                          $v.filtros.fecha_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="guia" class="col-md-5">Guía</label>
                    <input
                      type="text"
                      id="guia"
                      v-model="filtros.guia"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ticket" class="col-md-5">Ticket</label>
                    <input
                      type="text"
                      id="guia"
                      v-model="filtros.ticket"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="vehiculo" class="col-md-5">Vehículo</label>
                    <input
                      type="text"
                      id="vehiculo"
                      v-model="filtros.vehiculo"
                      class="form-control form-control-sm col-md-7 col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="remolque" class="col-md-5">Remolque</label>
                    <input
                      type="text"
                      id="remolque"
                      v-model="filtros.remolque"
                      class="form-control form-control-sm col-md-7 col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="doc_conductor" class="col-md-5"
                      >Doc. Conductor</label
                    >
                    <input
                      type="text"
                      id="doc_conductor"
                      v-model="filtros.doc_conductor"
                      class="form-control form-control-sm col-md-7 col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5">Estado Viaje</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado_viaje"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Transportadora"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.empresas"
                      @input="getSelectTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Sitio cargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_cargue"
                      label="nombre"
                      placeholder="Sitio Cargue"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitios"
                      @input="seleccionarSitioCargue()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Sitio descargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_descargue"
                      label="nombre"
                      placeholder="Sitio Descargue"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitios"
                      @input="seleccionarSitioDescargue()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      label="nombre"
                      placeholder="Ruta"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.rutas"
                      @input="seleccionarRuta()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Estado Bono</label>
                    <select
                      class="form-control form-control-sm p-0 col-md-7"
                      v-model="filtros.estado_bono"
                    >
                      <option value="">Seleccione...</option>
                      <option value="1">Generado</option>
                      <option value="2">Pagado</option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Fecha Pago</label>
                    <input
                      type="date"
                      class="form-control form-control-sm col-md-7 col-md-7"
                      v-model="filtros.fecha_pago"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('hidrocarburos.gestionBonosViajes.export') &&
                !$v.filtros.$invalid
            "
          >
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "GestionBonoExport",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      sitio_cargue: null,
      sitio_descargue: null,
      transportadora: null,
      ruta: null,
      filtros: {
        guia: null,
        ticket: null,
        vehiculo: null,
        remolque: null,
        doc_conductor: null,
        estado_viaje: null,
        transportadora_id: null,
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        ruta_id: null,
        estado_bono: null,
        fecha_pago: null,
        fecha_ini: null,
        fecha_fin: null,
      },

      listasForms: {
        estados: [],
        empresas: [],
        sitios: [],
        rutas: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        var fecha_menor = new Date(this.filtros.fecha_ini);
        var fecha_mayor = new Date(this.filtros.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        fecha_menor = new Date(this.filtros.fecha_ini).getTime();
        fecha_mayor = new Date(this.filtros.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getSelectTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
      } else {
        this.filtros.transportadora_id = null;
      }
    },

    seleccionarSitioCargue() {
      if (this.sitio_cargue != null) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      } else {
        this.filtros.sitio_cargue_id = null;
      }
    },

    seleccionarSitioDescargue() {
      if (this.sitio_descargue != null) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      } else {
        this.filtros.sitio_descargue_id = null;
      }
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      } else {
        this.filtros.ruta_id = null;
      }
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/bonoViaje/export",
        data: { filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    limpiarModal() {
      this.sitio_cargue = null;
      this.sitio_descargue = null;
      this.transportadora = null;
      this.ruta = null;
      this.filtros = {
        guia: null,
        ticket: null,
        vehiculo: null,
        remolque: null,
        doc_conductor: null,
        estado_viaje: null,
        transportadora_id: null,
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        ruta_id: null,
        estado_bono: null,
        fecha_pago: null,
        fecha_ini: null,
        fecha_fin: null,
      };
    },
  },

  async mounted() {
    await this.getEstados();
    await this.buscarTransportadora();
    await this.buscarSitios();
    await this.buscarRutas();
  },
};
</script>
